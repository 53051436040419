/* These styles make the body full-height */
html, body { height: 100%; background-color: midnightblue; }
/* These styles disable body scrolling if you are using <ScrollView> */
/* body {overflow: hidden; } */
/* These styles make the root element full-height */
#root { display:flex; height:100%; }
body { min-height: 430px; }

[data-testid="aws-btn-active-background"] + div {
  width: 100%;
  height: 100%;
}
