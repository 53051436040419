body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body:not(.showing-game) #root {
  display: none;
}

.showing-game > #root {
  display: inline;
}

.showing-game .non-game {
  display: none;
}

:not(.showing-game).auto-height-no-game {
  height: auto;
}

@import './css/bootstrap.min.css';
@import './css/font-awesome.min.css';
@import './css/templatemo_style.css';
